var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container-table",attrs:{"id":"usetype"}},[_c('button-all-header',{attrs:{"contentBtnAdd":'Thêm kiểu người dùng',"arrayExcel":[],"hideDelete":false,"hideImportFile":false,"hideExportFile":false,"hideDowload":false},on:{"clickAdd":function($event){return _vm.$router.push({ name: 'create-user-type' })},"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataListUserType || [],"select-options":{
      enabled: false,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'actionFunction')?_c('span',[_c('span',{on:{"click":function($event){return _vm.$router.push({name: 'edit-user-type', params: {id: props.row.id}})}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"EditIcon","size":"18"}})],1),_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.openModalDelete(props.row.id)}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"Trash2Icon","size":"18"}})],1)]):(props.column.field === 'isDefault')?_c('span',[(props.row.isDefault)?_c('span',[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"CheckIcon","size":"18"}})],1):_vm._e()]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.handlePageClick}}),_c('modal-delete-user-type',{attrs:{"id":_vm.confirmModalId},on:{"accept":_vm.deleteAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }